<template>
    <v-container fluid class="pt-0">
        <CreateModal  ref="create_modal"  :subsidiary="selectedSubsidiary" />
        <DetailsModal ref="details_modal" :subsidiary="selectedSubsidiary" />
        <EditModal    ref="edit_modal"    :subsidiary="selectedSubsidiary" />

        <span class="pl-5 pl-md-12 section-title">{{ $t('views.companies.subsidiaries.title') }}</span>
        <v-alert v-if="hasError" type="error" class="mt-4 mx-5 mt-md-6 mx-md-12">
            {{ $t('errors.generic') }}
        </v-alert>
        <SubsidiariesTable 
            v-else
            ref="table"
            class="my-4 mx-2 mx-md-10" 
            :fetchFunction="fetchFunction"
            @create="openCreate" 
            @details="openDetails" 
            @edit="openEdit"
            @delete="handleDelete"
        />
    </v-container>
</template>

<script>
import DetailsModal         from '@/components/modals/subsidiaries/DetailsModal'
import CreateModal          from '@/components/modals/subsidiaries/CreateModal'
import EditModal            from '@/components/modals/subsidiaries/EditModal'
import SubsidiariesTable    from '@/components/tables/SubsidiariesTable'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import Subsidiary           from '@/models/Subsidiary'
import Company              from '@/models/Company'

export default {
    data: () => ({
        selectedSubsidiary: new Subsidiary,
        hasError          : false,
    }),
    mixins: [ HasErrorHandlerMixin ],
    components: { SubsidiariesTable, CreateModal, DetailsModal, EditModal },
    methods: {
        async openCreate() {
            this.selectedSubsidiary = new Subsidiary()
            let r = await this.$refs.create_modal.open()
            if (!r)
                return

            this.$bus.$emit('message', this.$t('views.companies.subsidiaries.List.alerts.create_success'), 'success')
            await this.refreshTable()
        },
        async openDetails(subsidiary) {
            this.selectedSubsidiary = subsidiary
            this.$refs.details_modal.open()
        },
        async openEdit(subsidiary) {
            // Precisa criar uma nova instância
            this.selectedSubsidiary = new Subsidiary(this.$lodash.cloneDeep(subsidiary))
            let r = await this.$refs.edit_modal.open()
            if (!r) 
                return

            this.$bus.$emit('message', this.$t('views.companies.subsidiaries.List.alerts.edit_success'), 'success')
            await this.refreshTable()
        },
        async handleDelete(subsidiary) {
            await subsidiary.delete()
                .catch(this.preErrorHandler)

            if (this.hasError) {
                this.hasError = false
                return
            }

            this.$bus.$emit('message', this.$t('views.companies.subsidiaries.List.alerts.delete_success'), 'success')
            await this.refreshTable()    
        },
        async refreshTable() {
            await this.$refs.table.refresh()
        },
        async fetchFunction(pagination) {
            this.hasError = false
            let response  = await Company.listSubsidiaries(pagination)
                .catch(this.preErrorHandler)
            return Subsidiary.hydrate(this.$lodash.get(response, 'data', []))
        },
        preErrorHandler(e) {
            this.hasError = true
            this.errorHandler(e)
        },
    },
}
</script>