<template>
    <BaseModal ref="modal"
        @cancel="cancel" @confirm="confirm"
        v-model="isModalOpen"
        :title="title"
        :confirmButton="computedConfirmButton"
        :cancelButton="computedCancelButton"
        max-width="330px"
    >
        <v-row>
            <v-col cols="12" class="py-0">
                <cp-text-field
                    ref="name_field"
                    :label="$t('forms.SubsidiaryForm.name.label')"
                    readonly type="text"
                    v-model="subsidiary.name"
                />
            </v-col>
            <v-col cols="12" class="py-0">
                <cp-document-field
                    ref="document_field"
                    :label="$t('forms.SubsidiaryForm.document.label')"
                    readonly type="text"
                    :swapButton="false"
                    :useCNPJ="true"
                    v-model="subsidiary.document"
                />
            </v-col>
        </v-row>
    </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal'
import BaseModalMixin from '../BaseModalMixin'
import Subsidiary from '@/models/Subsidiary'

export default {
    name: 'SubsidiariesDetailsModal',
    mixins: [ BaseModalMixin ],
    components: { BaseModal },
    props: {
        subsidiary: {
            type: Subsidiary,
            required: true,
        },
    },
    data: vm => {
        return {
            title: vm.$t('views.companies.subsidiaries.Details.title'),
        }
    },
    computed: {
        computedConfirmButton() {
            return {
                label: this.$t('actions.close'),
            }
        },
        computedCancelButton() {
            return {
                hide: true,
            }
        },
    },
}
</script>