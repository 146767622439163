<template>
    <BaseModal ref="modal"
        @cancel="cancel" @confirm="triggerSubmit"
        v-model="isModalOpen"
        :title="title"
        :confirmButton="computedConfirmButton"
        :cancelButton="computedCancelButton"
        max-width="330px"
        persistent
    >
        <SubsidiaryForm
            ref="subsidiary_form"
            v-if="!loading"
            v-model="subsidiary"
            :isFormValid.sync="isFormValid"
            :formType="'EDIT'"
            @submit="confirm"
            @triggerSubmit="triggerSubmit"
            :loading="loading"
        />
        <span v-else>{{ $t('forms.wait') }}</span>
    </BaseModal>
</template>

<script>
import SubsidiaryForm from '@/components/forms/SubsidiaryForm'
import BaseModal from '@/components/modals/BaseModal'
import BaseModalMixin from '../BaseModalMixin'
import Subsidiary from '@/models/Subsidiary'

export default {
    name: 'SubsidiariesEditModal',
    mixins: [ BaseModalMixin ],
    components: { BaseModal, SubsidiaryForm },
    props: {
        subsidiary: {
            type: Subsidiary,
            required: true,
        },
    },
    data: vm => {
        return {
            isFormValid: false,
            title: vm.$t('views.companies.subsidiaries.Edit.title'),
            loading: false,
        }
    },
    methods: {
        triggerSubmit() {
            this.$refs.subsidiary_form.submit()
        },
        async beforeConfirm(subsidiary) {
            if (!this.isFormValid)
                return false

            this.loading = true
            subsidiary.formatToBack()

            let errType
            let response = await subsidiary.update()
                .catch(err => {                    
                    if (err.message == 'Network Error') {
                        errType = 'network_error'
                        return 
                    }

                    if (err.response.data.type) {
                        errType = err.response.data.type
                        return
                    }

                    errType = 'error'
                })

            this.subsidiary.formatToFront()
            this.loading = false
            
            if (errType) {
                const definedErrors = [
                    'used_document',
                    'network_error'
                ]
                errType = definedErrors.includes(errType) ? errType : 'error'
                await new Promise((resolve) => {
                    this.$bus.$emit('alert', this.$t(`views.companies.subsidiaries.Edit.alerts.${errType}`), 'error', resolve)
                })
                return false
            }

            this.resolveValue = this.subsidiary
            return true
        },
    },
    computed: {
        computedConfirmButton() {
            return {
                label: this.$t('actions.save'),
                disabled: !this.isFormValid,
                loading: this.loading,
            }
        },
        computedCancelButton() {
            return {
                disabled: this.loading,
            }
        },
    },
}
</script>