var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        title: _vm.title,
        confirmButton: _vm.computedConfirmButton,
        cancelButton: _vm.computedCancelButton,
        "max-width": "330px"
      },
      on: { cancel: _vm.cancel, confirm: _vm.confirm },
      model: {
        value: _vm.isModalOpen,
        callback: function($$v) {
          _vm.isModalOpen = $$v
        },
        expression: "isModalOpen"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [
              _c("cp-text-field", {
                ref: "name_field",
                attrs: {
                  label: _vm.$t("forms.SubsidiaryForm.name.label"),
                  readonly: "",
                  type: "text"
                },
                model: {
                  value: _vm.subsidiary.name,
                  callback: function($$v) {
                    _vm.$set(_vm.subsidiary, "name", $$v)
                  },
                  expression: "subsidiary.name"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [
              _c("cp-document-field", {
                ref: "document_field",
                attrs: {
                  label: _vm.$t("forms.SubsidiaryForm.document.label"),
                  readonly: "",
                  type: "text",
                  swapButton: false,
                  useCNPJ: true
                },
                model: {
                  value: _vm.subsidiary.document,
                  callback: function($$v) {
                    _vm.$set(_vm.subsidiary, "document", $$v)
                  },
                  expression: "subsidiary.document"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }