<template>
    <BaseCrudTable 
        ref="table" 
        tableName="SubsidiariesTable" 
        :headerConfigs="headerConfigs"
        :fetchFunction="fetchFunction"
        :pagination="pagination" 
        @create="handleCreate" 
        @details="handleDetails"
        @edit="handleEdit" 
        @delete="handleDelete"
    />
</template>

<script>
import BaseCrudTable from '@/components/tables/BaseCrudTable'
import Subsidiary    from '@/models/Subsidiary'

export default {
    name: 'SubsidiariesTable',
    props: {
        fetchFunction: {
            type: Function,
            required: true,
        },
    },
    components: { BaseCrudTable },
    data: () => ({
        pagination: {
            page    : 1,
            per_page: 5,
        },  
        headerConfigs: [
            {
                value: 'name', 
                showOnMobile: true,
            },
            {
                value: 'document',
                showOnMobile: false,
            },
        ],
    }),
    created() {
        this.pagination.page = Number(this.$route.query.page) || this.pagination.page || 1
    },
    methods: {
        async refresh() {
            await this.$refs.table.preFetchItems()
        },
        handleCreate() {
            this.$emit('create')
        },
        handleDetails(item) {
            this.$emit('details', item)
        },
        handleEdit(item) {
            this.$emit('edit', item)
        },
        handleDelete(item) {
            this.$emit('delete', item)
        },
    },
}
</script>