var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [
              _c("cp-text-field", {
                ref: "name_field",
                attrs: {
                  autofocus: "",
                  label: _vm.$t("forms.SubsidiaryForm.name.label"),
                  placeholder: _vm.$t("forms.type_here"),
                  required: "",
                  type: "text",
                  "error-messages": _vm.getErrorMessages("name")
                },
                on: {
                  input: function($event) {
                    return _vm.clearErrorMessages("name")
                  }
                },
                model: {
                  value: _vm.value.name,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "name", $$v)
                  },
                  expression: "value.name"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [
              _c("cp-document-field", {
                ref: "document_field",
                attrs: {
                  label: _vm.$t("forms.SubsidiaryForm.document.label"),
                  placeholder: _vm.$t(
                    "forms.ParentCompanyForm.document.placeholder"
                  ),
                  required: "",
                  type: "text",
                  swapButton: false,
                  useCNPJ: true,
                  "error-messages": _vm.getErrorMessages("document")
                },
                on: {
                  input: function($event) {
                    return _vm.clearErrorMessages("document")
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.$emit("triggerSubmit")
                  }
                },
                model: {
                  value: _vm.value.document,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "document", $$v)
                  },
                  expression: "value.document"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }