<template>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <v-row>
            <v-col cols="12" class="py-0">
                <cp-text-field
                    ref="name_field"
                    autofocus
                    :label="$t('forms.SubsidiaryForm.name.label')"
                    :placeholder="$t('forms.type_here')"
                    required type="text"
                    :error-messages="getErrorMessages('name')"
                    v-model="value.name"
                    @input="clearErrorMessages('name')"
                />
            </v-col>
            <v-col cols="12" class="py-0">
                <cp-document-field
                    ref="document_field"
                    :label="$t('forms.SubsidiaryForm.document.label')"
                    :placeholder="$t('forms.ParentCompanyForm.document.placeholder')"
                    required type="text"
                    :swapButton="false"
                    :useCNPJ="true"
                    :error-messages="getErrorMessages('document')"
                    v-model="value.document"
                    @input="clearErrorMessages('document')"
                    @keyup.enter="$emit('triggerSubmit')"
                />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import Company       from '@/models/Company'
import FormMixin     from '@/mixins/FormMixin'

export default {
    name: 'SubsidiaryForm',
    mixins: [ FormMixin(Company, 'name_field') ],
    props: {
        company: Object,
        formType: String
    }
}
</script>